import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'src/env';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private _http: HttpService, private http: HttpClient) {}

  /**
   *
   * @param id
   * @param type
   * @note :- Smart searching for dropowns
   */
  smartSearchById(id: any, type: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/smart-search`,
      {
        params: { id: id, type: type },
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param query
   * @param type
   * @note :- Smart searching for dropowns
   */
  smartSearchByQuery(query: any, type: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/smart-search`,
      {
        params: { q: query, type: type },
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param query
   * @param type
   * @param verified
   * @note :- Smart searching for dropowns
   */
  smartSearchByQueryVerified(query: any, type: any, verified: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/smart-search`,
      {
        params: { q: query, type: type, verified: verified },
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @returns institutes
   */
  getInstituteByDoctor(doctor_id: any) {
    return this.http.get(
      `${env.API_URL}/frontend/doctor/${doctor_id}/institutes`,
      {
        headers: this._http.header,
      }
    );
  }
}
