import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private _alert: SnackbarService,private _auth:AuthService) {}

  /**
   *
   * @param error
   * @param formname
   * @retuen Reactive form Validator Error in Form
   */

  public __handler(err: any, form: any) {
    const validationErrors = err.error.errors
      ? err.error.errors
      : err.error.data;
    return Object.keys(validationErrors).forEach((ele) => {
      const formControl = form.get(ele);
      if (formControl) {
        formControl.setErrors({
          servererror: validationErrors[ele],
        });
      } else {
        this.__error(err);
      }
    });
  }

  /**
   *
   * @param error
   * @return Error Message in Sncakbar Throw Error Code
   */
  __error(err: any) {
    if (err.status == 422) {
      const ResponseError = err.error.errors;
      Object.keys(ResponseError).forEach((ele) => {
        this._alert.openSnackBar(ResponseError[ele], 'X','error-snackbar');
      });
    }
    if (err.status == 404) {
      this._alert.openSnackBar(err.error.message, 'X','error-snackbar');
    }
    if (err.status == 405) {
      this._alert.openSnackBar(err.error.message, 'X','error-snackbar');
    }
    if (err.status == 401) {
      this._auth.logout();
      this._alert.openSnackBar(err.error.message, 'X','error-snackbar');
    }
    if (err.status == 400) {
      this._alert.openSnackBar(err.error.message, 'X','error-snackbar');
    }
  }
}
