import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/helpers/http.service';
import { env } from 'src/env';

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  constructor(private _http: HttpService, private http: HttpClient) {}

  /**
   * @model name
   */
  private model = 'doctors';

  /**
   * get doctors list
   */
  list(params?) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}`,
      {
        params: params,
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * get doctors by id
   */
  show(id: number) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctors
   * @add doctors
   */
  add(doctors: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}`,
      doctors,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctors
   * @param id
   * @update doctors
   */
  update(doctors: any, id: number) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      doctors,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @delete doctor
   */
  delete(id: number) {
    return this.http.delete(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   * get institute list(approved)
   */
  instituteList(params?) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/institutes/summary`,
      {
        params: params,
        headers: this._http.header,
      }
    );
  }


   /**
   * get institutes by city id
   * **/
    getInstitutesByCityId(city_id: any) {
      return this.http.get(
        `${env.API_URL}/${env.API_GROUP_FRONTEND}/home/city/${city_id}/institutes`,
        {
          headers: this._http.header
        }
      );
    }

  /**
   * change doctor email
   */
  changeDoctorEmail(data: any, id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/change/email`,
      data,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   * change doctor phone
   */
  changeDoctorPhone(data: any, id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/change/phone`,
      data,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @send Email
   */

  sendEmailVerify(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/send/verification/mail`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @send Phone
   */

  sendPhoneVerify(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/send/verification/sms`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @get getsubscription list
   */

  getSubscription(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/subscriptions`,
      {
        headers: this._http.header,
      }
    );
  }
  /**
   *
   * @param id
   * @get getfeedback list
   */

  getRatings(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/ratings`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @param rating_id
   * @get get rating flag list
   */

  getRatingsFlag(doctor_id: any, rating_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/ratings/${rating_id}/flags`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @param rating_id
   * @get get rating like list
   */

  getRatingsLike(doctor_id: any, rating_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/ratings/${rating_id}/likes`,
      {
        headers: this._http.header,
      }
    );
  }
  /**
   *
   * @param doctor_id
   * @get get doctor gallery list
   */

  getDoctorGallery(doctor_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/gallery`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor
   * @data doctor
   * @get get doctor gallery list
   */

  addGalleryPhoto(doctor: any, doctor_id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/gallery`,
      doctor,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @data doctor
   * @get get doctor gallery list
   */

  updateGalleryPhoto(doctor: any, doctor_id: any, update_id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/gallery/${update_id}`,
      doctor,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @get get doctor gallery list
   */

  deleteGalleryPhoto(doctor_id: any, delete_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/gallery/${delete_id}/delete`,

      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @get get doctor gallery list
   */

  showGalleryPhoto(doctor_id: any, show_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/gallery/${show_id}`,

      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param doctor_id
   * @get get profile aprooved
   */

  profileApproved(doctor_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/profile/approved`,

      {
        headers: this._http.header,
      }
    );
  }

   /**
   *
   * @param doctor_id
   * @get get profile aprooved
   */

    createStAccount(doctor_id: any) {
      return this.http.get(
        `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/create/sehatalk`,
        {
          headers: this._http.header,
        }
      );
    }
  /**
   *
   * @param doctor_id
   * @get get profile aprooved
   */

  profileRejected(data, doctor_id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${doctor_id}/profile/rejected`,
      data,

      {
        headers: this._http.header,
      }
    );
  }
}
