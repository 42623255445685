import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/helpers/http.service';
import { env } from 'src/env';

@Injectable({
  providedIn: 'root',
})
export class InstitueService {
  constructor(private _http: HttpService, private http: HttpClient) {}

  /**
   * @model name
   */
  private model = 'institutes';

  /**
   * get institutes list
   */
  list(params?) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}`,
      {
        params: params,
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * get institutes by id
   */
  show(id: number) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institutes
   * @add institutes
   */
  add(institutes: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}`,
      institutes,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institutes
   * @param id
   * @update institutes
   */
  update(institutes: any, id: number) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      institutes,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @delete institute
   */
  delete(id: number) {
    return this.http.delete(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   * get institute list(approved)
   */
  instituteList(params?) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/institutes/summary`,
      {
        params: params,
        headers: this._http.header,
      }
    );
  }

  /**
   * change institute email
   */
  changeInstituteEmail(data: any, id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/change/email`,
      data,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   * change institute phone
   */
  changeInstitutePhone(data: any, id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/change/phone`,
      data,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @send Email
   */

  sendEmailVerify(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/send/verification/mail`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @send Phone
   */

  sendPhoneVerify(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/send/verification/sms`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param id
   * @get getsubscription list
   */

  getSubscription(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/subscriptions`,
      {
        headers: this._http.header,
      }
    );
  }
  /**
   *
   * @param id
   * @get getfeedback list
   */

  getRatings(id) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${id}/ratings`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute_id
   * @param rating_id
   * @get get rating flag list
   */

  getRatingsFlag(institute_id: any, rating_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/ratings/${rating_id}/flags`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute_id
   * @param rating_id
   * @get get rating like list
   */

  getRatingsLike(institute_id: any, rating_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/ratings/${rating_id}/likes`,
      {
        headers: this._http.header,
      }
    );
  }
  /**
   *
   * @param institute_id
   * @get get institute gallery list
   */

  getInstituteGallery(institute_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/gallery`,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute
   * @data institute
   * @get get institute gallery list
   */

  addGalleryPhoto(institute: any, institute_id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/gallery`,
      institute,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute_id
   * @data institute
   * @get get institute gallery list
   */

  updateGalleryPhoto(institute: any, institute_id: any, update_id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/gallery/${update_id}`,
      institute,
      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute_id
   * @get get institute gallery list
   */

  deleteGalleryPhoto(institute_id: any, delete_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/gallery/${delete_id}/delete`,

      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute_id
   * @get get institute gallery list
   */

  showGalleryPhoto(institute_id: any, show_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/gallery/${show_id}`,

      {
        headers: this._http.header,
      }
    );
  }

  /**
   *
   * @param institute_id
   * @get get profile aprooved
   */

  profileApproved(institute_id: any) {
    return this.http.get(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/profile/approved`,

      {
        headers: this._http.header,
      }
    );
  }
  /**
   *
   * @param institute_id
   * @get get profile aprooved
   */

  profileRejected(data, institute_id: any) {
    return this.http.post(
      `${env.API_URL}/${env.API_GROUP_BACKEND}/${this.model}/${institute_id}/profile/rejected`,
      data,

      {
        headers: this._http.header,
      }
    );
  }
}
